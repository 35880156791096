'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                        .first()
                        .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.nav-item.dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category" role="button"></li>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                li.append(link);
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                $(this).parent().children('.dropdown-menu')
                    .prepend(li)
                    .prepend(closeMenu)
                    .attr('aria-hidden', 'false');
                // copy navigation menu into view
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                $(link).focus();
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                e.preventDefault();
            }
        })  
        .on('mouseenter', function () {
            if (isDesktop(this)) {
                var eventElement = this;
                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement)) {
                        $(this).find('.show').each(function () {
                            clearSelection(this);
                        });
                        if ($(this).hasClass('show')) {
                            $(this).removeClass('show');
                            $(this).children('ul.dropdown-menu').removeClass('show');
                            $(this).children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // need to close all the dropdowns that are not direct parent of current dropdown
                $(this).parent().addClass('show');
                $(this).siblings('.dropdown-menu.tecnifibre-menu').addClass('show');
                $(this).attr('aria-expanded', 'true');
            }
        })
          .parent()
          .on('mouseleave', function () {
                if (isDesktop(this)) {
                    $(this).removeClass('show');
                    $(this).children('.dropdown-menu').removeClass('show');
                   $(this).children('.nav-link').attr('aria-expanded', 'false');
                }
           });

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
    });

    $('.navbar-nav,.main-menu').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.navbar-nav,.main-menu').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');

        $('.modal-background').hide();
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $('.main-menu').toggleClass('in');
        $('.modal-background').show();

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');

        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
    });

    keyboardAccessibility('.main-menu .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.main-menu .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.main-menu .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('.main-menu .user').on('mouseenter focusin', function () {
        if ($('.main-menu .user .popover').length > 0) {
            $('.main-menu .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
        }
    });

    $('.main-menu .user').on('mouseleave', function () {
        $('.main-menu .user .popover').removeClass('show');
        $('.user').attr('aria-expanded', 'false');
    });
    $('body').on('click', '#myaccount', function () {
        event.preventDefault();
    });
};
 /* PANNEAU RECHERCHE */
 $('.btn-recherche').on('click', function (e) {

    e.preventDefault();
    if ($(this).hasClass('active')) {
        $('.zone-recherche').slideUp("slow");
        $(this).removeClass('active');
        $('.masque-noir').fadeOut(500, function () {
            $(this).remove();
        });
    }
    else {
        $('.zone-recherche').slideDown("slow");
        $(".site-search input").focus();
        $(this).addClass('active');
        if ($('.masque-noir').length === 0) {
            $('body').append('<div class="masque-noir"></div>');
            $('.masque-noir').fadeIn(500);
        }
    }

    var topOfDiv = $('body').offset().top;
    var bottomOfVisibleWindow = $(window).height();
    var tobottom = bottomOfVisibleWindow - topOfDiv - 100;

    // var height = $('.bg').height();
    $('body,html').animate({scrollTop:topOfDiv}, 1000);
    return false;

});
window.fermerTout = function(){
    $('.zone-recherche').slideUp();
    $('.btn-recherche').removeClass('active');
    $('.masque-noir').fadeOut(500, function () {
        $(this).remove();
    });
  }
$(document).on("click",".masque-noir", function(e) {
    e.preventDefault();
    fermerTout();
});
$(document).on("click",".recherche-b", function(e) {
    e.preventDefault();
    fermerTout();
});
$('.navbar-nav > li').children('ul.dropdown-menu').addClass('tecnifibre-menu');
$('.dropdown-item').children('ul.dropdown-menu').addClass('tecnifibre-menu2');

$('.dropdown-menu.tecnifibre-menu2').removeClass('show');

$('.dropdown-menu.tecnifibre-menu').children('li').addClass('tec-menu-padding');
// $('.navbar.bg-inverse .navbar-nav .nav-link').on('mouseenter', function () {
//     if ($('.masque-noir').length === 0) {
//         $('body').append('<div class="masque-noir"></div>');
//         $('.masque-noir').fadeIn(500);
//     }

// })
// .on('mouseleave', function () {
//     $('.masque-noir').fadeOut(500, function () {
//         $(this).remove();
//     });
// });


$('.tec-product').on('mouseenter', function () {
    var btnadd = $(this).find('.quickview');
    btnadd.css('opacity','1');
});
$('.tec-product').on('mouseleave', function () {
    var btnadd = $(this).find('.quickview');
    btnadd.css('opacity','0');
});

// function hauteurBanniere(){
//     var hauteur_fenetre = $('header').height();
//     $('.tec-page').css({ 'margin-top' : hauteur_fenetre+'px' });
// }
// hauteurBanniere();

// $(window).on('resize', function(){
//     hauteurBanniere()
// });


$('.tec-bar-price').find('.strike-through').next().addClass('color-bar');


$(".accordion-title").click(function(e){
    if ($(window).width() > 751) {
    var accordionitem = $(this).attr("data-tab");
    $("#"+accordionitem).slideToggle();

    $(this).toggleClass("tec-arrow");
    $("#"+accordionitem).removeClass("tec-arrow");
    }else{
        var accordionitem = $(this).attr("data-tab");
        $("#"+accordionitem).slideToggle().parent().siblings().find(".accordion-content").slideUp();
    
        $(this).toggleClass("tec-arrow");
        $("#"+accordionitem).parent().siblings().find(".accordion-title").removeClass("tec-arrow");
    }
});

    $('.select-wrapper,.custom-select').click(function(e){
        $(this).toggleClass("select-arrow");
    });

    function hauteurBanniere(){
        if ($(window).width() > 751) {
        var hauteur_fenetre = $('header').height();
        $('.tec-page').css({ 'margin-top' : hauteur_fenetre+'px'});
        }
    }
    hauteurBanniere();
    $(window).on('resize', function(){
        hauteurBanniere()
    });

    $(document).on('click','.navbar-toggler',function(e){
        e.preventDefault();
        $('.menu-toggleable-left').addClass('navbar-scroll');
        $('nav.navbar').addClass('element-scrolled');
        $('.menu-toggleable-left').show();
        $('html body').addClass('fixed-body');
    })

    $(document).on('click','.close-button',function(e){
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('navbar-scroll');
        $('nav.navbar').removeClass('element-scrolled');
        $('.menu-toggleable-left').hide();
        $('html body').removeClass('fixed-body');
    })

    //   function hauteurBanniere(){

    //     if ( $(window).width() > 768 ) {

    //         var heightmenu = $(".tec-header").height();
    //               $(".tec-page").css('margin-top',heightmenu +30);
    //               $(".zone-recherche").css('top',heightmenu  + 30.5);
    //               };
    //   }
    //   hauteurBanniere();
    //   $(window).on('resize', function(){
    //     hauteurBanniere()
    // });
